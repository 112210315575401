#blogText{
    color: #e5e5e5;
}
#indent{
    margin-top: 28px;
    margin-bottom: 28px;
    margin-left: 6px;
    margin-right:6px;
    background-color: #1d263b;
    border-radius: 8px;
    padding: 12px 16px;
}
#sectionTitle{
    font-size: 1.5rem;
    font-weight: bolder;
}
#subSectionTitle{
    font-size: 1.1rem;
    font-weight: 600;
}
#bold{
    color: white;
    font-weight: bolder;
    margin-top: 0.6rem;
    margin-bottom: 0.2rem;
}
